<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push('/tabs/clubs/' + this.$route.params.id)"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.trainers')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list>
        <ion-item v-for="(item, key) in items" :key="key">
          <avatar slot="start" :value="item.profile_photo_tn"
                  :initials="`${item.first_name.substring(0,1)}${item.last_name.substring(0,1)}`"></avatar>
          <ion-label v-text="`${item.first_name} ${item.last_name}`"></ion-label>
          <ion-icon slot="end" :icon="mailOutline" color="primary" @click="actionSendMessage(item)"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonRefresher, IonRefresherContent, loadingController, IonIcon} from "@ionic/vue";
import {API} from "@/utils/apiWrapper";
import Avatar from "../../components/Avatar";
import {mailOutline} from 'ionicons/icons';

export default {
  name: "ClubViewTrainers",
  components: {Avatar, IonPage, IonRefresherContent, IonRefresher, IonIcon},
  setup() {
    return {
      mailOutline
    }
  },
  data() {
    return {
      items: []
    }
  },
  methods: {
    actionSendMessage(item) {
      this.$router.push(`/tabs/clubs/${this.$route.params.id}/trainers/${item.cognito_id}/message`)
    },
    doRefresh(event) {
      this.loadData().finally(() => {
        event.target.complete()
      })
    },
    loadData() {
      return API.get('TheSpottApi', '/club-trainers', {
        queryStringParameters: {
          id: this.$route.params.id
        }
      }).then(response => {
        this.items = response.data
      })
    }
  },
  async ionViewWillEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()

    this.loadData().finally(() => {
      loading.dismiss()
    })
  }
}
</script>